<template>
  <div>
    <Row class="p-t-10">
      <i-col span="24">
          <p class="remark p-b-5">* 按时间段导出全部线路在刊汇总</p>
          <i-button size="small" type="success" class="m-l-5" @click.native="initPage">导出全部线路在刊汇总</i-button>
      </i-col>
    </Row>

    <Modal v-model="modalShow" width="400" title="导出全部线路在刊汇总" footer-hide>
        <Row class="p-b-5">
            <i-col span="24">
                <label class="title">开始时间</label>
                <DatePicker v-model="startDate" :clearable="false" type="date" placeholder="请选择开始时间" style="width: 200px"></DatePicker>
            </i-col>
        </Row>

        <Row class="p-b-10">
            <i-col span="24">
                <label class="title">结束时间</label>
                <DatePicker v-model="endDate" :clearable="false" type="date" placeholder="请选择开始时间" style="width: 200px"></DatePicker>
            </i-col>
        </Row>

        <Row class="p-b-10">
            <i-col span="4">
                <label class="title">在刊类型</label>
            </i-col>
            <i-col span="20">
              <CheckboxGroup v-model="issueType">
                <Checkbox label="1">商业</Checkbox>
                <Checkbox label="2">填充</Checkbox>
                <Checkbox label="3">公益</Checkbox>
              </CheckboxGroup>
            </i-col>
        </Row>

        <Row>
          <i-col span="24">
            <i-button size="small" type="success" class="m-l-50" @click="handleDownload">开始下载</i-button>
          </i-col>
        </Row>
    </Modal>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { ParseDate } from '@/utils/dateFormat'

export default {
  data () {
    return {
      modalShow: false,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      startDate: '',
      endDate: '',
      issueType: ['1'] // 1:商业;2:填充;3:公益;
    }
  },
  methods: {
    initPage () {
      this.startDate = new Date().getFullYear() + '-01-01'
      this.endDate = new Date()

      this.modalShow = true
    },
    handleDownload () {
      const downloadParams = {
        publisherId: this.publisherId,
        startDate: ParseDate(this.startDate),
        endDate: ParseDate(this.endDate),
        issueTypes: this.issueType.join(',')
      }

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/datav/maintain/exportResourceIssueGbAssetAndDay',
        downloadParams, '资源在刊率统计.xlsx')
    }
  }
}
</script>
